import * as React from "react"

const returnNextMassDate = () => {
    const Now = new Date();

    let FirstBreakpoint = new Date(Now.getFullYear(), 2, 24, 23, 59, 59, 0);
    let SecondBreakpoint = new Date(Now.getFullYear(), 9, 14, 23, 59, 59, 0);

    FirstBreakpoint = returnSundayAfterDate(FirstBreakpoint);
    SecondBreakpoint = returnSundayAfterDate(SecondBreakpoint);

    let resultDate = new Date();

    if(Now<FirstBreakpoint)
        resultDate = FirstBreakpoint;
    else if( Now >= FirstBreakpoint && Now <SecondBreakpoint)
        resultDate = SecondBreakpoint;
    else if(Now>=SecondBreakpoint){
        let nextYear = new Date(Now.getFullYear()+1, 2, 24, 23, 59, 59, 0);
        resultDate = returnSundayAfterDate(nextYear);
    }

    return resultDate;
}

const returnNextBurialDate = () => {
    const Now = new Date();

    let Breakpoint = new Date(Now.getFullYear(), 9, 14, 23, 59, 59, 0);

    Breakpoint = returnSundayAfterDate(Breakpoint);

    let resultDate = new Date();

    if(Now<Breakpoint)
        resultDate = Breakpoint;
    else if(Now>=Breakpoint){
        let nextYear = new Date(Now.getFullYear()+1, 9, 14, 23, 59, 59, 0);
        resultDate = returnSundayAfterDate(nextYear);
    }

    return resultDate;
}


const returnSundayAfterDate = (date) => {
    const sunday = new Date(date);
    sunday.setDate(sunday.getDay===0?sunday.getDate():sunday.getDate()+(7-sunday.getDay()));
    return sunday;
}

const parseDateToString = (date) => {
    const d = new Date(date);
    return d.getDate()+"."+(d.getMonth()<=8?"0":"")+(d.getMonth()+1)+"."+d.getFullYear()+"r.";
}

const NextBurialDate = (props) => {
  return (
    <span>{parseDateToString(props.onlymass?returnNextMassDate():returnNextBurialDate())}</span>
  )
}

export default NextBurialDate;
