import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby";

const ArticleContainer = styled.article`
    display: flex;
    flex-direction: column;

    margin: 10px 20px;
`

const ArticleHeader = styled.div`
    font-size:30px;
    font-weight:bold;
    font-family: "Lato", sans-serif;
    color: #363537;

    cursor: pointer;
    transition: color .3s;

    &:hover{
        color:#818181;
    }

    &:after{
        content:"";
        height: 3px;
        width: 100px;
        background-color: #F5C0C0;
        margin-top: 1px;

        display: block;
    }
`

const ArticleDate = styled.div`
    font-size: 16px;
    color: #CCCBCD;
    font-family: "Roboto", sans-serif;
    margin-top: 3px;
`

const ArticleBody = styled.div`
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    color: #3D3C3E;

    margin: 10px 2px;

    cursor: pointer;
    transition: color .3s;

    &:hover{
        color:#818181;
    }
`

const convertDate = (input) => {
    let conv = input.split("-");
    return conv[2]+"-"+conv[1]+"-"+conv[0];
}

const Article = (props) => {
  return (
    <ArticleContainer>
        <ArticleHeader><Link to={`/artykuly/${props.slug}`}>{props.title}</Link></ArticleHeader>
        <ArticleDate>{convertDate(props.date)}</ArticleDate>
        <ArticleBody><Link to={`/artykuly/${props.slug}`}><div className="DatoCMS">{props.shorttext}</div></Link></ArticleBody>
    </ArticleContainer>
  )
}

export default Article;
