import * as React from "react"
import styled from "styled-components"

import { breakpoint } from "styled-components-breakpoint";

const Button = styled.div`
    color:#FF7171;
    width: fit-content;
    position: relative;

    font-family: "Roboto", sans-serif;

    font-size: 30px;
    margin: 40px 10px;
    ${breakpoint("tablet") `
          font-size: 36px;
          margin: 40px 20px;
    `}
    ${breakpoint("desktop") `
          font-size: 40px;
    `}

    font-weight: bold;
    text-transform: uppercase;
    text-align: center;



    padding: 12px 22px;
    transition: .4s linear;

    z-index: 1;

    cursor: pointer;

    &:after{
        content:"";
        position: absolute;
        top: 7px;
        left: -7px;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 5px #FF7171;

        transition: .3s linear;
    }

    &:before{
        content:"";
        position: absolute;
        top: -7px;
        left: 7px;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 5px #F5C0C0;
        transition: .3s linear;
    }

    &:hover{
        background: #eee;
        color: #FF7070;
    }

    &:hover:before{
        top: 0px;
        left: 0px;
    }

    &:hover:after{
        top: 0px;
        left: 0px;
    }
`

const ButtonMovingFrames = (props) => {
  return (
    <Button>{props.children}</Button>
  )
}

export default ButtonMovingFrames;
